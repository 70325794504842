import { marked } from "marked";
import { useSpring, animated } from "@react-spring/web";
import { useCatch, useLoaderData } from "@remix-run/react";
import { MetaFunction } from "@remix-run/node";
import Image from "remix-image";
import { checkEnvVars } from "~/utils/errorHandling";

export async function loader({ params }: any) {
    checkEnvVars();

    const resPost = await fetch(
        process.env.STRAPI_URL_BASE +
            "/api/pages?filters[slug]=" +
            params.page +
            "&populate=*",
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
                "Content-Type": "application/json",
            },
        },
    )
        .then((res) => res.json())
        .catch((error) => {
            console.log(error.message);
            throw new Response("Error getting data from Backend", {
                status: 500,
            });
        });

    return resPost.data[0];
}

export const meta: MetaFunction = ({ data }) => {
    if (!data) {
        return {
            title: "404",
            description: "Ada kesalahan, silahkan kembali ke halaman beranda",
        };
    }
    const seo = data.attributes;
    return {
        title: seo.title + " | CV. Gema Nusa",
        description: seo.description,
        "og:type": "website",
        "og:url": ENV.WEB_URL + "/" + seo.slug,
        "og:title": seo.title,
        "og:description": seo.description,
        "og:image":
            ENV.BACKEND_URL + seo.image.data?.attributes.formats.medium.url,
        "twitter:image":
            ENV.BACKEND_URL + seo.image.data?.attributes.formats.medium.url,
        "twitter:card": "summary_large_image",
        "twitter:creator": "CV. Gema Nusa",
        "twitter:site": "CV. Gema Nusa",
        "twitter:title": seo.title,
        "twitter:description": seo.description,
    };
};

export default function Page() {
    let page = useLoaderData().attributes;
    let contentWithUrlUploads = marked(
        page.content.split("/uploads/").join(ENV.BACKEND_URL + "/uploads/"),
    );

    const contentSpring = useSpring({
        to: { opacity: 1, transform: "translate(0, 0)" },
        from: { opacity: 0, transform: "translate(0, 2rem)" },
    });

    return (
        <div className="mb-12 -mt-10">
            <div className="h-80 w-full bg-gradient-to-t from-sky-500 to-sky-300 overflow-hidden z-10 relative">
                {/* breadcumb */}
                <div className="mt-8 absolute -translate-x-1/2 left-1/2 z-20">
                    <nav
                        className="py-2 px-4 mx-auto bg-white w-fit rounded-xl"
                        aria-label="Breadcrumb"
                    >
                        <ol
                            role="list"
                            className="flex items-center text-sm text-gray-500 space-x-1"
                        >
                            <li>
                                <a
                                    className="block transition-colors hover:text-gray-700"
                                    href="/"
                                >
                                    <span className="sr-only"> Home </span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-4 h-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                        />
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </li>

                            <li>
                                <a
                                    className="block transition-colors hover:text-gray-700"
                                    href={`/${page.slug}`}
                                >
                                    {" "}
                                    {page.title}{" "}
                                </a>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="w-full h-full mx-auto flex flex-col items-center justify-center bg-gradient-to-t from-gray-700 absolute -translate-y-1/2 top-1/2 left-0 z-10 px-10 md:px-24 pb-12">
                    <animated.h2
                        style={contentSpring}
                        className="max-w-screen-lg text-3xl font-semibold text-gray-100 leading-tight text-center"
                    >
                        {page.title}
                    </animated.h2>
                </div>
                {page.image.data?.attributes.url ? (
                    <Image
                        className="w-full h-full object-cover object-center"
                        alt={"image " + page.title}
                        src={
                            ENV.BACKEND_URL +
                            page.image.data?.attributes?.formats.medium.url
                        }
                        loaderUrl="/api/image"
                        dprVariants={[1, 3]}
                    />
                ) : (
                    <span className="w-full absolute left-0 top-1/4 z-20 text-white text-9xl font-extrabold uppercase text-center opacity-20">
                        {page.title}
                    </span>
                )}
            </div>

            {/* content render */}
            <animated.article
                style={contentSpring}
                className="prose -mt-24 p-4 md:p-8 text-base leading-relaxed text-gray-700 mx-4 md:mx-auto max-w-screen-lg bg-white rounded-xl relative z-20"
                dangerouslySetInnerHTML={{ __html: contentWithUrlUploads }}
            />
        </div>
    );
}

export function CatchBoundary() {
    const caught = useCatch();

    return (
        <div className="h-screen flex flex-col justify-center flex-1 text-red-500 boxPattern">
            <div className="leading-none text-center">
                <h1 className="font-mono text-[25vw]">{caught.status}</h1>
                <pre>
                    <code>{JSON.stringify(caught.data)}</code>
                </pre>
            </div>
        </div>
    );
}

export function ErrorBoundary({ error }: any) {
    return (
        <div className="h-96 mx-8 flex flex-col justify-center flex-1 text-red-500 boxPattern">
            <div className="leading-none text-center">
                <h1 className="font-mono font-bold text-[16vw] mb-4">404</h1>
                <p>
                    Ada kesalahan, silahkan kembali ke halaman{" "}
                    <a href="/" className="underline">
                        beranda
                    </a>
                </p>
            </div>
        </div>
    );
}
